<template>
    <div class="modal-help" :style="options.style">
        <div class="head">
            <div class="flex-row flex-between items-start">
                <div class="title" v-html="$translate(options.title)"/>
                <i @click="$emit('close')" class="zmdi zmdi-close"/>
            </div>
            <div class="title-desc" v-html="$translate(options.titleDesc)"/>
        </div>
        <div class="hr"/>
        <div class="body">
            <div
                :key="idx"
                v-for="(item, idx) in options.items">
                <span class="key">{{ item.key | translate }}</span>
                <span> : </span>
                <span class="value">{{ item.value | translate }}</span>
            </div>
        </div>
        <div class="footer" :class="options.footerClass" v-html="$translate(options.footer)"/>
    </div>
</template>
<script>
export default {
    name: 'ModalHelp',
    props: ['options'],
}
</script>